import { getDefaultPath } from "@iso/lib/helpers/url_sync";
import actions, { getView } from "./actions";
import { isServer } from "@iso/lib/helpers/isServer";
const preKeys = getDefaultPath();
const initState = {
  // collapsed: !isServer && window.innerWidth > 1220 ? false : true,
  // view: !isServer && getView(window.innerWidth),
  // height: !isServer && window.innerHeight,
  // openDrawer: false,
  // openKeys: preKeys,
  // current: preKeys,
  // notificationActionType: "",
  // actor: "doctor",
  // addModal: false,
  // notifications: [],
  newOrder:false,
  wounds:false,
  prescriptions:false
};

export default function Pulse(state = initState, action) {
  switch (action.type) {
    case "orders.new_order":
      return {
        ...state,
        newOrder: true,
      };
    case "RemoveOrder":
      return {
        ...state,
        newOrder: false,
      };
      case "wounds.new_wound":
        return {
          ...state,
          wounds: true,
        };
      case "RemoveWounds":
        return {
          ...state,
          wounds: false,
        };
        case "prescriptions.new_prescription":
          return {
            ...state,
            prescriptions: true,
          };
        case "RemovePrescriptions":
          return {
            ...state,
            prescriptions: false,
          };
    // case "ClOSE_ADD_MODAL":
    //   return {
    //     ...state,
    //     addModal: false,
    //   };
    // case actions.SELECT_ACTOE:
    //   return {
    //     ...state,
    //     actor: action.payload,
    //   };
    // case actions.REMOVE_NOTIFICATION:
    //   return {
    //     ...state,
    //     notificationActionType: "",
    //   };
    // case actions.SET_NOTIFICATION_ACTION:
    //   return {
    //     ...state,
    //     notificationActionType: action.payload,
    //   };
    // case actions.COLLPSE_OPEN_DRAWER:
    //   return {
    //     ...state,
    //     openDrawer: !state.openDrawer,
    //   };
    // case actions.TOGGLE_ALL:
    //   if (state.view !== action.view || action.height !== state.height) {
    //     const height = action.height ? action.height : state.height;
    //     return {
    //       ...state,
    //       collapsed: action.collapsed,
    //       view: action.view,
    //       height,
    //     };
    //   }
    //   break;
    // case actions.CHANGE_OPEN_KEYS:
    //   return {
    //     ...state,
    //     openKeys: action.openKeys,
    //   };
    // case actions.CHANGE_CURRENT:
    //   return {
    //     ...state,
    //     current: action.current,
    //   };
    // case actions.CLEAR_MENU:
    //   return {
    //     ...state,
    //     openKeys: [],
    //     current: [],
    //   };
    // case actions?.ADD_NOTIFICATION:
    //   return {
    //     ...state,
    //     notifications: [...state.notifications, action.payload],
    //   };
    //   case actions.DELETE_NOTIFICATION:
    //     return {
    //       ...state,
    //       notifications: state.notifications.filter(
    //         notification => notification !== action.payload
    //       ),
    //     };
    // case actions?.UPDATE_NOTIFICATION:
    //   return {
    //     ...state,
    //     notifications: state.notifications.map((notification, index) =>
    //       index === action.payload.index
    //         ? action.payload.newNotification
    //         : notification
    //     ),
    //   };
    default:
      return state;
  }
  return state;
}
