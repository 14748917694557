import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";
var firebaseConfig = {
  apiKey: "AIzaSyBC4nv0xZP7a1qQ1KtfbBIedg5vuBuNWPo",
  authDomain: "medtroops-321209.firebaseapp.com",
  projectId: "medtroops-321209",
  storageBucket: "medtroops-321209.appspot.com",
  messagingSenderId: "1032032729182",
  appId: "1:1032032729182:web:eb36506033efa991af1ccf",
  measurementId: "G-5MSKXNSC3Q",
};

initializeApp(firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);

// const messaging = getMessaging(firebaseApp);
const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    console.log("Firebase not supported this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export const getTokenB = async (setTokenFound) => {
  try {
    const messagingResolve = await messaging;
    const currentToken = await getToken(messagingResolve, {
      // vapidKey: *your FCM APP SERVER KEY*,
    });
    if (currentToken) {
      console.log("current token for client: ", currentToken);
      setTokenFound(currentToken);
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = async () =>

  new Promise((resolve) =>
    (async () => {
      const messagingResolve = await messaging;
      onMessage(messagingResolve, (payload) => {
        // console.log('On message: ', messaging, payload);
        resolve(payload);
      });
    })()
  );
